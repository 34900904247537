<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CRow class="row-block mb-12"
        >
            <CCol sm="4" lg="4"  v-for="subject in subjects"
            :key="subject.subjectName">
                <!-- <a
                    class="card-block stretched-link text-decoration-none"
                    :href="'/#/details/'+subject.subjectName"
                > -->
                <!-- <a
                    class="card-block stretched-link text-decoration-none"
                    :href="'/#/subjectwise/'+subject.subject.subject_id"
                > -->
                <a
                  class="card-block stretched-link text-decoration-none"
                  @click="go_next(subject.subject.subject_id,subject.subject.teacher_id,subject.subject.subject_name)"
                >
                <StatWidgetBox v-bind:data="subject" v-bind:header="subject.subject.subject_name" v-bind:colors="subject.subjectColor" color="gradient-green" v-bind:text="subject.count" v-bind:footer="subject.subject.expr4"/>
               </a>
               <!-- <CRow>
                   <CCol>
                       Teacher
                   </CCol>
               </CRow> -->
            </CCol>
        </CRow>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-book"/> Latest Content
            <div class="card-header-actions">
                <!-- <small class="text-muted">
                    <CButton color="primary" @click="add_contents">Add + </CButton>
                </small> -->
            </div>
          </CCardHeader>
          <CCardBody>
              <CCard v-for="(items,index) in latest_content" :key="index">
                  <CCardHeader >
                      <CIcon name="cil-justify-center"/>
                      <strong> {{items.title}} </strong>
                      <div class="card-header-actions">
                      <a 
                          href="https://coreui.io/vue/docs/components/jumbotron" 
                          class="card-header-action" 
                          rel="noreferrer noopener" 
                          target="_blank"
                      >
                          <small class="text-muted">PDF</small>
                      </a>
                      </div>
                  </CCardHeader>
                  <CCardBody >
                      <CJumbotron style="padding:15px 10px; padding-left:30px;">
                      <h4>{{items.subject_name}} - {{items.title}}</h4>
                      <p class="lead">{{items.remarks}}</p>
                      <!-- <p>Class - 5</p> -->
                      <a  :href="'https://mahavidya-contents.s3.ap-south-1.amazonaws.com/'+items.file_type" target="_blank" class="mx-3">
                        <CIcon name="cil-book"/>
                      </a>&nbsp;
                      <CButton  size = "sm" color="info" variant="outline" @click="open_file(items.file_type)"><CIcon name="cil-cloud-download"/></CButton>
                      <!-- <CButton  size = "sm" color="primary" variant="outline"><CIcon name="cil-file"/></CButton>&nbsp;
                      <CButton  size = "sm" color="info" variant="outline"><CIcon name="cil-cloud-download"/></CButton> -->
                      </CJumbotron>
                  </CCardBody>
              </CCard>
            </CCardBody>
        </CCard>
        <!-- <UserProfileCard /> -->
      </CCol>
    </CRow>
  </div>
</template>

<script>
import StatWidgetBox from "../app-main-views/stats-widgets/StatWidgetBox.vue";
import WidgetStats from "../app-main-views/stats-widgets/WidgetStats";
import UserProfileCard from "../app-main-views/user-profile-card/UserProfileCard";
import CloudStorageChart from "../app-main-views/cloud-storage-chart/CloudStorageChart";
import QuickNoteForm from "../app-main-views/forms/QuickNoteForm";
import axios from 'axios';
import apis from '@/apis';
const base_url=process.env.VUE_APP_BASE_URL;

export default {
  name: "Dashboard",
  data() {
      return{
      //     subjects: [
      //   {
      //     subjectName: "Marathi",
      //     teacherName: "Ms. Aruna Patil",
      //     totalUploads: 4,
      //     subjectColor: "warning"
      //   },
      //   {
      //     subjectName: "Hindi",
      //     teacherName: "Mr. Vinit Desai",
      //     totalUploads: 6,
      //     subjectColor: "success"
      //   },
      //   {
      //     subjectName: "English",
      //     teacherName: "Mr. B. V. Patil",
      //     totalUploads: 5,
      //     subjectColor: "primary"
      //   },
      //   {
      //     subjectName: "Social Science",
      //     teacherName: "Mr. Chandu Mali",
      //     totalUploads: 3,
      //     subjectColor: "secondary"
      //   },
      //   {
      //     subjectName: "Maths",
      //     teacherName: "Mr. Chandu Mali",
      //     totalUploads: 7,
      //     subjectColor: "danger"
      //   },
      //   {
      //     subjectName: "Science",
      //     teacherName: "Ms. Sangeeta Deshpande",
      //     totalUploads: 9,
      //     subjectColor: "info"
      //   },
      //   {
      //     subjectName: "Other Material",
      //     teacherName: "District Authorities",
      //     totalUploads: 2,
      //     subjectColor: "secondary"
      //   }
      // ]
      subjects:[],
      latest_content:[]
    
  }
  },
  methods:{
    open_file(item){
      this.file_link = item;
      var url = 'https://mahavidya-contents.s3.ap-south-1.amazonaws.com/' +this.file_link;
      window.open(url, "_blank");
            
    },
    go_next(data,data2,data3){
      this.$router.push({"name":"subjectwise",params:{
              "teacher_id": data2,"subject_id": data,"subject":data3
            }})
    },
    get_latest_data(){
       var promise = apis.latest_data().then( response => {
          this.latest_content = response.data;
      }).catch( errror => {

      })
    },
    get_dashboard_data(){
      var promise = apis.dashboard_data().then( response => {
          this.subjects = response.data;
      }).catch( errror => {

      })
    }
  },
  components: {
    WidgetStats,
    UserProfileCard,
    CloudStorageChart,
    QuickNoteForm,
    StatWidgetBox
  },
  created(){
    this.get_dashboard_data();
    this.get_latest_data();
    let user_id = this.$session.get('user_id');
    let user_role_id = this.$session.get('user_role_id');
    if(user_id == undefined || user_id == null)
    {
      return '/';
      //this.$router.push('/auth/login');
    }
    if(user_role_id != '8290f938-b20a-42e3-c201-08d8f3241655')
    {
      return '/';
    } 
          
  }
};
</script>
